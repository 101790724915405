<template>
    <div class="admin2List">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="标识或名称"  @keyup.enter.native='loadAdmin2List'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadAdmin2List'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='openAdmin2(null)'>添加</el-button>
                </el-form-item>
                <el-form-item>
                    <jsonExcel class="export-excel-wrapper" :data="jsonData" :fields="jsonFields" :beforeGenerate="excelOutput" name="二级单位管理员名单.xls">
                        <el-button type="danger" size ="mini" icon="search"  :loading="jsonLoading">导出</el-button>
                    </jsonExcel>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="name" label="用户名"  align='center' ></el-table-column>
                <el-table-column prop="nick_name" label="姓名" align='center' ></el-table-column>
                <el-table-column prop="school_name" label="所属单位" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button type="warning" size="mini" @click='openAdmin2Pass(scope.row)'>更改密码</el-button>
                        <el-button size="mini" @click='openAdmin2(scope.row)'>编辑</el-button>
                        <el-button type="danger" icon='edit' size="mini" @click='onDeleteAdmin2(scope.row)'>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <admin2Dialog ref="admin2Dialog" :admin2="selAdmin2" @ok="loadAdmin2List"></admin2Dialog>
        <admin2PassDialog ref="admin2PassDialog" :admin2Pass="selAdmin2" @ok="loadAdmin2List"></admin2PassDialog>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import admin2Dialog from "./admin2Dialog";
    import admin2PassDialog from "./admin2PassDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'admin2List',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:true, isShow:false, jsonLoading: false,
                selAdmin2: null, selAdmin2Dtl: null,
                companyList: [], deptList: [], jsonData: [],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
                jsonFields: { "用户名": { field: "name", callback: value =>{ return "&nbsp;" + value;}
                    }, "姓名": "nick_name", "所属单位": "school_name", }
            }
        },
        components:{
            admin2Dialog, admin2PassDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadAdmin2List();
	   },
        methods: {

            loadAdmin2List(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId, orderBy: 'a.uddt desc'};
                let param = { controllerName: 'sclAdmin', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                });
            },
            excelOutput(){
                this.jsonLoading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId, orderBy: 'a.uddt desc'};
                let param = { controllerName: 'sclAdmin', methodName: '/list', pageNo: 1, pageSize: 9999999, param: search};
                return simpleApi.listWait(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.jsonData = list;
                    }
                    this.jsonLoading = false;
                }).catch((error) => {
                    this.jsonLoading = false;
                    console.log("error")
                });
            },
            formatterStatus(row, column, cellValue, index) {

                if(cellValue == '00'){
                    return '正常';
                }else if(cellValue == '01'){
                    return '禁用';
                }
                return cellValue;

            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadAdmin2List()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadAdmin2List()
            },
            onDeleteAdmin2(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该任务以及明细, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'sclAdmin', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadAdmin2List()
                        }
                        if (code == -3) {
                            that.$message({ type: 'error', message: '该管理员下已有数据，请先清除数据后再删除！'});
                            return ;
                        }
                    });
                }).catch(() => {

                });
            },

            openAdmin2(row){
                if(row != null){
                    this.selAdmin2 = row;
                }else{
                    this.selAdmin2 = { id: null, licenceId: null,  schoolId: null, name: null, nickName: null, pass: null, status: '00', time: (new Date()).getMilliseconds()};
                }
                this.$refs.admin2Dialog.showDialog();
            },
            openAdmin2Pass(row){
                if(row != null){
                    this.selAdmin2 = row;
                    this.$refs.admin2PassDialog.showDialog();
                }
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


