<template>
    <div class="admin2Dialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="admin2Form" ref="admin2DialogForm" :rules="admin2Rules">

                <el-form-item label="用户名"  prop="admin2Key" :label-width="formLabelWidth">
                    <el-input v-model="admin2Form.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="姓名"  prop="admin2Name" :label-width="formLabelWidth">
                    <el-input v-model="admin2Form.nickName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" v-if="admin2Form.id == null" prop="pass" :label-width="formLabelWidth">
                    <el-input v-model="admin2Form.pass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位：" prop="schoolId" :label-width="formLabelWidth">
                    <el-select v-model="admin2Form.schoolId" filterable placeholder="请选择">
                        <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'admin2Dialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                schoolList: [], selRoleList: [],
                admin2Form:{ id: null,  licenceId: null,  schoolId: null, name: null, nickName: null, pass: null, status: '00',},
                admin2Rules: {
                    name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                    schoolId: [{ required: true, message: '请选择单位', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadSchoolList();
	    },
        props: {
            admin2: {
                type: Object
            }
        },
        watch: {
            admin2: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.admin2Form = { id: n.id, licenceId: n.licence_id,  schoolId: n.school_id ,name: n.name, nickName: n.nick_name, pass: null,
                            status: n.status };
                        if(n.id == null){
                            this.admin2Form.name = this.userInfo.licenceKey + 'admin' + String((new Date()).getMilliseconds()).substr(0,2) ;
                        }
                        if(n.id == null) this.myTitle = '新建单位管理员'; else this.myTitle = '编辑单位管理员';
                        this.admin2Form.pass = this.userInfo.licenceKey + '123';
                    }else{
                        this.admin2Form = { id: null, licenceId: null,  schoolId: null, name: null, nickName: null, pass: null, status: '00',};
                    }

                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadSchoolList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'school', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.schoolList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            submitForm(){

                console.log(this.admin2Form);
                let that = this;
                this.$refs.admin2DialogForm.validate((valid) => {
                    if(valid){
                        if(this.admin2Form.id == null && (this.admin2Form.pass == null || this.admin2Form.pass == '')){
                            this.$message.error("请输入密码");
                            return ;
                        }
                        this.loading = true;
                        this.admin2Form.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'sclAdmin', methodName: '/saveOrUpdate', param: this.admin2Form};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('admin2', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该用户已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .admin2Dialog{

    }


</style>


