<template>
    <div class="admin2PassDialog">
        <el-dialog title="修改密码" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="admin2PassForm" ref="admin2PassDialogForm" :rules="admin2PassRules">
                
                <el-form-item label="新密码"  prop="pass" :label-width="formLabelWidth">
                    <el-input v-model="admin2PassForm.pass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码"  prop="pass" :label-width="formLabelWidth">
                    <el-input v-model="admin2PassForm.passConfirm" type="password" autocomplete="off"></el-input>
                </el-form-item>
                
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'admin2PassDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, loading: false,
                roleList: [], selRoleList: [],
                admin2PassForm:{ id: null, pass: null, passConfirm: null,},
                admin2PassRules: {
                    passConfirm: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }],
                    pass: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
        },
      	mounted() {

	    },
        props: {
            admin2Pass: {
                type: Object
            }
        },
        watch: {
            admin2Pass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.admin2PassForm = { id: n.id, pass: null, passConfirm: null, };
                    }else{
                        this.admin2PassForm = { id: null, pass: null, passConfirm: null,};
                    }

                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){


                let that = this;
                this.$refs.admin2PassDialogForm.validate((valid) => {
                    if(valid){
                        if(this.admin2PassForm.pass != this.admin2PassForm.passConfirm){
                            this.$message.error("两次密码不一致");
                            return ;
                        }
                        this.loading = true;
                        let param = { controllerName: 'sclAdmin', methodName: '/changePass', param: this.admin2PassForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('admin2Pass', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .admin2PassDialog{

    }


</style>


